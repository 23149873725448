* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

.MuiTab-root {
    min-width: initial !important;
    padding: 0 40px !important;
}

img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

.header-top {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}